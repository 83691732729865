import React, { useState, useEffect, useRef, useContext } from "react";
import wax from "./../../img/wax.png";
import { useLocation } from "react-router-dom";
import { verify } from "../Wax/Transactions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Verify({ ual, accountName }) {
  const query = useQuery();
  const type = query.get("type");
  const [info, setInfo] = useState();
  const [wasSend, setWasSend] = useState(false);

  useEffect(() => {
    if (type == 335) {
      setInfo({ spn: type });
    } else if (type == 1000) {
      setInfo({ spn: type });
    } else if (type == 2000) {
      setInfo({ spn: type });
    }
  }, [type]);

  const verifyNow = async () => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;

      const transactionPayload = verify(accountName);

      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      setWasSend(true);

      console.log("transaction", transaction);
    } catch (e) {
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  };

  return (
    <>
      <div className={`list-for-buy`}>
        <img src={wax} width={69} />

        {wasSend ? (
          <>
            <div className="tit green">
              <h3>Successful</h3>
            </div>
            <div className="descr">
              The verification request has been successfully created.
            </div>
          </>
        ) : (
          <>
            <div className="form-spn">
              <div className="tit">
                <h3>Your wallet</h3>
              </div>
              <div className="inp">
                <span>{accountName}</span>
              </div>

              <button onClick={() => verifyNow()} className="bt">
                Verify
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Verify;
