import React, { useState, useEffect, useRef, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UALContext } from "ual-reactjs-renderer";
import BuyComponent from "./components/BuyComponent/BuyComponent";
import TestComponent from "./components/TestComponent/TestComponent";
import "./App.scss";
import wax from "./img/wax.png";

import { arrRpcAt, savedOptionAt } from "./components/Wax/Variables";
import Verify from "./components/Verify/Verify";
const { ExplorerApi, RpcApi } = require("atomicassets");
var numRpc = 0;

const App = () => {
  const [accountName, setAccountName] = useState(null);

  const ual = useContext(UALContext);
  const login = () => {
    ual.logout();
    ual.showModal();
  };
  const logout = () => {
    ual.logout();
    setAccountName(null);
  };

  useEffect(() => {
    if (
      ual?.activeUser?.accountName !== null &&
      ual?.activeUser?.accountName !== accountName &&
      ual?.activeUser?.accountName !== undefined
    ) {
      setAccountName(ual.activeUser.accountName);
    }
  }, [ual]);

  return (
    <>
      <BrowserRouter>
        <div className="app-wrapper">
          <div className="wrapper">
            {/* {accountName && (
              <>
                <div className="user-name">Welcome, {accountName}</div>
                <nav>
                  <ul>
                    <li>
                      <button onClick={() => logout()}>Logout</button>
                    </li>
                  </ul>
                </nav>
              </>
            )} */}

            {
              accountName ? (
                <>
                  <Routes>
                    <Route path="" element={"222"} />
                    <Route path="/market" element={"3333"} />
                    <Route
                      path="/spn"
                      element={
                        <BuyComponent accountName={accountName} ual={ual} />
                      }
                    />
                    <Route
                      path="/verify"
                      element={<Verify accountName={accountName} ual={ual} />}
                    />
                  </Routes>
                </>
              ) : (
                <div className="login">
                  <img src={wax} width={69} />
                  <button className="bt" onClick={() => login()}>
                    Connect wallet
                  </button>
                </div>
              )
              // (
              //   <Navigate to="/" />
              // )
            }
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};
// ---

export default App;
