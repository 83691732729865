export const registration = (accountName, referral) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "registration",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          referral: referral,
        },
      },
    ],
  };
};

export const withdrawNftFromGame = (accountName, ids) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "withdrawnft",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          to: accountName,
          asset_ids: ids,
        },
      },
    ],
  };
};

export const transferToTheGame = (accountName, ids) => {
  return {
    actions: [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor: accountName, // use account that was logged in
            permission: "active",
          },
        ],
        data: {
          from: accountName,
          to: "spinniaworld",
          asset_ids: ids,
          memo: "",
        },
      },
    ],
  };
};

export const buySlot = (accountName, sum) => {
  return {
    actions: [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [
          {
            actor: accountName, // use account that was logged in
            permission: "active",
          },
        ],
        data: {
          from: accountName,
          to: "spinniaworld",
          quantity: sum,
          memo: "buyslots ",
        },
      },
    ],
  };
};

export const addToSlot = (accountName, asset_id) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "addtoslot",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_id: asset_id,
          slot: 0,
        },
      },
    ],
  };
};

export const addRuna = (accountName, asset_id, runa_id) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "addruna",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_id: asset_id,
          runa_id: runa_id,
        },
      },
    ],
  };
};

export const remRuna = (accountName, runa_id) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "outruna",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_id: runa_id,
        },
      },
    ],
  };
};

export const remFromSlot = (accountName, asset_id) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "remfromslot",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_id: asset_id,
        },
      },
    ],
  };
};

export const claim = (accountName, asset_id) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "claim",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_id: asset_id,
        },
      },
    ],
  };
};

export const spin = (accountName, asset_ids) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "spin",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_ids: asset_ids,
        },
      },
    ],
  };
};

export const actvipspin = (accountName, asset_id, e) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "actvipspin",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_id: asset_id,
          status: e,
        },
      },
    ],
  };
};

export const tokenDeposite = (accountName, sum, memo) => {
  return {
    actions: [
      {
        account: "spinniatoken",
        name: "transfer",
        authorization: [
          {
            actor: accountName, // use account that was logged in
            permission: "active",
          },
        ],
        data: {
          from: accountName,
          to: "spinniaworld",
          quantity: sum,
          memo: memo,
        },
      },
    ],
  };
};

export const craft = (accountName, sum, type) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "craft",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          quantity: sum,
          type: type,
        },
      },
    ],
  };
};

export const tokenWithdraw = (accountName, sum, fee) => {
  return {
    actions: [
      {
        account: process.env.REACT_APP_TOKEN_WAX,
        name: "transfer",
        authorization: [
          {
            actor: accountName, // use account that was logged in
            permission: "active",
          },
        ],
        data: {
          from: accountName,
          to: "spinniaworld",
          quantity: "25.00000000 WAX",
          memo: "withdrawspn - paying a commission for withdrawing a token from the game",
        },
      },
      {
        account: "spinniaworld",
        name: "withdrawt",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          to: accountName,
          quantity: sum,
          fee: fee,
        },
      },
    ],
  };
};

export const verify = (accountName) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "verify",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
        },
      },
    ],
  };
};

export const buyMult = (accountName, quantity) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "buymult",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          quantity: quantity,
        },
      },
    ],
  };
};

export const buyPack = (accountName, sum, memo) => {
  return {
    actions: [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [
          {
            actor: accountName, // use account that was logged in
            permission: "active",
          },
        ],
        data: {
          from: accountName,
          to: "spinniaworld",
          quantity: sum,
          memo: memo,
        },
      },
    ],
  };
};

export const regLottBoost = (accountName, asset_ids, tmpl_access) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "reglottb",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          asset_ids: asset_ids,
          tmpl_access: tmpl_access,
        },
      },
    ],
  };
};

export const regLottOffer = (accountName, id, number) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "reglottoff",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          id: id,
          number: number,
        },
      },
    ],
  };
};

export const withdrawTicket = (accountName, count) => {
  return {
    actions: [
      {
        account: "spinniaworld",
        name: "withdrtick",
        authorization: [
          {
            actor: accountName,
            permission: "active",
          },
        ],
        data: {
          user: accountName,
          count: count,
        },
      },
    ],
  };
};
