import React, { useState, useEffect, useRef, useContext } from "react";
import wax from "./../../img/wax.png";
import { useLocation } from "react-router-dom";
import { tokenDeposite } from "../Wax/Transactions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function BuyComponent({ ual }) {
  const query = useQuery();
  const type = query.get("type");
  const [info, setInfo] = useState();
  const [wasSend, setWasSend] = useState(false);

  useEffect(() => {
    if (type == 335) {
      setInfo({ spn: type });
    } else if (type == 1000) {
      setInfo({ spn: type });
    } else if (type == 2000) {
      setInfo({ spn: type });
    }
  }, [type]);

  const sendSpn = () => {
    tokenDepositeTrans();
  };

  const tokenDepositeTrans = async () => {
    try {
      const { activeUser } = ual;
      const { accountName } = activeUser;
      const detalPrice = parseInt(info.spn).toFixed(4) + " SPN";

      const transactionPayload = tokenDeposite(
        accountName,
        detalPrice,
        "spntoclash"
      );

      console.log("transactionPayload", transactionPayload);

      const transaction = await activeUser.signTransaction(transactionPayload, {
        blocksBehind: 3,
        expireSeconds: 30,
      });

      setWasSend(true);

      console.log("transaction", transaction);
    } catch (e) {
      console.log(e);
      // if (e instanceof RpcError)
      //   console.log(JSON.stringify(e.json, null, 2));
    }
  };

  return (
    <>
      <div className={`list-for-buy`}>
        <img src={wax} width={69} />

        {wasSend ? (
          <>
            <div className="tit green">
              <h3>Successful</h3>
            </div>
            <div className="descr">
              The purchase request has been successfully submitted and will be
              processed shortly.
            </div>
          </>
        ) : (
          <>
            <div className="tit">
              <h3>Silver purchase</h3>
            </div>
            <div className="descr">
              <p>
                All the details for the purchase have already been entered.
                Click "Transfer" and then confirm the transaction.
              </p>
              <p>
                It may take up to 10 minutes to process the transaction. You can
                find the status in your profile settings.
              </p>
            </div>

            <div className="form-spn">
              <div className="tit">
                <h3>Transfer to</h3>
              </div>
              <div className="inp">
                <span>spinniaworld</span>
              </div>

              <div className="tit">
                <h3>Amount</h3>
              </div>
              <div className="inp">
                <span>{info?.spn} SPN</span>
              </div>

              <div className="tit">
                <h3>Memo</h3>
              </div>
              <div className="inp">
                <span>spntoclash</span>
              </div>
              <button onClick={() => sendSpn()} className="bt">
                Transfer
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default BuyComponent;
