import React from 'react'
import { createRoot } from 'react-dom/client';
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { UALProvider, withUAL } from 'ual-reactjs-renderer'
import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';
import { Wombat } from 'wombat-ual'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { JsonRpc } from 'eosjs'
import { Buffer } from 'buffer';

global.Buffer = Buffer;

const waxMainnet = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [{
    protocol: 'https',
    host: "wax.cryptolions.io",
    port: '443',
  }]
}

const appName = "spinner";

const endpoint = `${waxMainnet.rpcEndpoints[0].protocol}://${waxMainnet.rpcEndpoints[0].host}:${waxMainnet.rpcEndpoints[0].port}`;
const rpc = new JsonRpc(endpoint);

const anchor = new Anchor([waxMainnet], { appName: appName });
const waxcloud = new Wax([waxMainnet], { appName: appName });
const wombat = new Wombat([waxMainnet], { appName: appName })

const root = createRoot(document.getElementById('root'));

root.render(
  <>
    <UALProvider
      appName={appName}
      authenticators={[waxcloud, anchor, wombat]}
      chains={[waxMainnet]}
    >
      <App rpc={rpc} />
    </UALProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
