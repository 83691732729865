export const arrRpc = ['wax.cryptolions.io', 'wax.greymass.com', 'api.wax.alohaeos.com', 'api.waxsweden.org', 'wax.eosphere.io', 'api.wax.greeneosio.com', 'wax.eu.eosamsterdam.net', 'api.wax.bountyblok.io'];
export const savedOption = localStorage.getItem('selectedServer');
export const savedOptionAt = localStorage.getItem('selectedServerAtomic');

export const mult = [
    [0, 1.1, 1.325, 1.55, 1.775, 2],
    [0, 1.1, 1.325, 1.55, 1.775, 2],
    [0, 1.1, 1.35, 1.6, 1.85, 2.1],
    [0, 1.1, 1.375, 1.65, 1.925, 2.2],
    [0, 1.1, 1.4, 1.7, 2, 2.3],
    [0, 1.1, 1.45, 1.8, 2.15, 2.5]
];


export const arrRpcAt = ["wax.api.atomicassets.io", "aa-wax-public1.neftyblocks.com",
    //"api.wax-aa.bountyblok.io", 
    "aa.dapplica.io", "api.atomic.greeneosio.com", "atomic.wax.eosrio.io", "wax-aa.eu.eosamsterdam.net", "atomic-wax-mainnet.wecan.dev", "atomic.sentnl.io", "atomic.tokengamer.io", "atomic.ledgerwise.io", "api-wax-aa.eosarabia.net",
    //"wax.hkeos.com/aa", 
    "wax-atomic.wizardsguild.one", "wax-atomic.eosiomadrid.io", "aa.wax.blacklusion.io", "wax-atomic-api.eosphere.io", "wax-aa.eosdublin.io"]